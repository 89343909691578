import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Hero from "./Hero";
import HeroHeader from "./HeroHeader";
import Navbar from "./Navbar";
import OurTeam from "./OurTeam";
import Portfolio from "./Portfolio";
import SplashScreen from "./SplashScreen";

function Home() {
  // use ref values for onpage navigation
  const portfolioSecRef = useRef(null);
  const teamSecRef = useRef(null);
  const blogSecRef = useRef(null);
  const contactUsSecRef = useRef(null);

  return (
    <div className="w-screen">
      {/* {isLoading && <SplashScreen />} */}

      {/* {!isLoading && ( */}
      <React.Fragment>
        <Navbar
          portfolioSecRef={portfolioSecRef}
          teamSecRef={teamSecRef}
          contactUsSecRef={contactUsSecRef}
        />

        <Hero />

        <HeroHeader />

        <Portfolio portfolioSecRef={portfolioSecRef} />

        <OurTeam teamSecRef={teamSecRef} />

        <Footer contactUsSecRef={contactUsSecRef} />
      </React.Fragment>
      {/* )} */}
    </div>
  );
}

export default Home;
