import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export default function QuoteTxtAnim() {
  const [counter, setCounter] = useState(0);
  const [text, setText] = useState("");
  const texts = ["Listen", "Observe", "Read", "Think"];

  let txtToAnimate: any = useRef(texts[0]);

  const animTxtComp = (txt: any) => {
    return (
      <AnimatePresence>
        <div>
          <motion.div
            key={txt}
            initial={{ y: "30vh", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2, type: "spring", stiffness: 100 }}
            className="absolute bottom-10 sm:bottom-56 right-8 sm:right-32 text-xl sm:text-6xl font-extrabold text-primary-500"
          >
            {txt}
          </motion.div>
        </div>
      </AnimatePresence>
    );
  };

  useEffect(() => {
    const anim = setInterval(() => {
      setText(texts[counter]);
      txtToAnimate.current = animTxtComp(text);
      if (counter !== texts.length - 1) {
        setCounter((c) => c + 1);
      } else {
        setCounter(0);
      }
    }, 4000);

    return () => {
      clearInterval(anim);
    };
  }, [counter]);

  return <div>{txtToAnimate.current}</div>;
}
