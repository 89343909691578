import { teamMembers } from "./data/teamMembers";

const ProfileCard = ({
  profileImg,
  name,
  position,
  desc,
}: {
  profileImg: any;
  name: any;
  position: any;
  desc: any;
}) => {
  return (
    <div className="flex flex-col justify-center items-center w-64 h-auto">
      <div className="w-28 sm:w-36">
        <img src={profileImg} alt={name} className="rounded-full" />
      </div>

      <div className="my-4 sm:my-6">
        <h1 className="text-lg sm:text-xl mb-1 uppercase text-center">
          {name}
        </h1>
        <h1 className="font-extralight text-center text-sm sm:text-base text-primary-700">
          {position}
        </h1>
      </div>

      <p className="text-center text-[10px] sm:text-xs font-extralight">
        {desc}
      </p>
    </div>
  );
};

function OurTeam({ teamSecRef }: { teamSecRef: any }) {
  return (
    <div className="bg-black text-white py-40" ref={teamSecRef}>
      <div className="flex justify-center items-center py-28">
        <h3 className="text-xl sm:text-4xl font-extralight">
          <span className="mx-2 sm:mx-4">O</span>
          <span className="mx-2 sm:mx-4">U</span>
          <span className="mx-2 sm:mx-4">R</span>
          <span className="mx-4 sm:mx-6"></span>
          <span className="mx-2 sm:mx-4">T</span>
          <span className="mx-2 sm:mx-4">E</span>
          <span className="mx-2 sm:mx-4">A</span>
          <span className="mx-2 sm:mx-4">M</span>
        </h3>
      </div>

      <div className="flex flex-col justify-center items-center gap-20 sm:px-5 w-full">
        {teamMembers.map((row) => (
          <div className="flex flex-col sm:flex-row justify-center items-center gap-20 w-full">
            {row.map((member) => (
              <ProfileCard
                profileImg={member.profileImg}
                name={member.name}
                position={member.position}
                desc={member.desc}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurTeam;
