import { motion } from "framer-motion";

function HeroHeader() {
  return (
    <div className="absolute top-0 left-0 sm:left-1/3">
      <div className="sm:relative sm:pt-20">
        <div className="bg-slate-900 w-full sm:h-screen"></div>
        <div className="sm:absolute top-2/4 left-1/3 -mt-36 -ml-20 text-white">
          {/* <h1 className="text-9xl tracking-widest">MELLA</h1> */}
          <h1 className="-ml-6 flex justify-start font-semibold items-center gap-20 sm:text-9xl ">
            <motion.span
              initial={{ x: "-10vh", y: "-10vh", opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              transition={{ duration: 1.5, type: "tween" }}
            >
              M
            </motion.span>
            <motion.span
              initial={{ x: "-10vh", y: "10vh", opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              transition={{ duration: 1.5, type: "tween" }}
            >
              E
            </motion.span>
            <motion.span
              initial={{ x: "-10vh", y: "-10vh", opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              transition={{ duration: 1.5, type: "tween" }}
            >
              L
            </motion.span>
            <motion.span
              initial={{ x: "-10vh", y: "10vh", opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              transition={{ duration: 1.5, type: "tween" }}
            >
              L
            </motion.span>
            <motion.span
              initial={{ x: "-10vh", y: "-10vh", opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              transition={{ duration: 1.5, type: "tween" }}
            >
              A
            </motion.span>
          </h1>
          <div className="my-8">
            <ul className="flex justify-between items-center text-xl font-extralight">
              <li>
                <motion.a
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 3 }}
                  href="#"
                >
                  ARCHITECTURE
                </motion.a>
              </li>
              <motion.div
                initial={{ scale: 5 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}
                className="w-3 h-3 bg-primary-500 rounded-full"
              ></motion.div>
              <li>
                <motion.a
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 3 }}
                  href="#"
                >
                  MOTION DESIGN
                </motion.a>
              </li>
              <motion.div
                initial={{ scale: 5 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}
                className="w-3 h-3 bg-primary-500 rounded-full"
              ></motion.div>
              <li>
                <motion.a
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 3 }}
                  href="#"
                >
                  MELLA-VERSE
                </motion.a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroHeader;
