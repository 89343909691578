import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const PortfolioCard = ({
  to,
  className,
  imgLoc,
  text,
}: {
  to: any;
  imgLoc: any;
  text: string;
  className?: any;
}) => {
  return (
    // <div className="bg-gradient-to-r from-black to-[#012627]">
    <div className={`${className}`}>
      <Link to={to}>
        <motion.div
          whileHover={{
            scale: 1.15,
            transition: { duration: 0.35 },
          }}
          className={`flex flex-col justify-center items-center w-40 sm:w-60 h-40 sm:h-60 hover:border hover:border-primary-300 border-gradient p-4`}
        >
          <div>
            <div className="w-20 sm:w-28 m-3 p-3">
              <img src={imgLoc} alt={text} />
            </div>
          </div>
          <h3 className="uppercase text-center text-xs sm:text-lg">{text}</h3>
        </motion.div>
      </Link>
    </div>
    // </div>
  );
};

function Portfolio({ portfolioSecRef }: { portfolioSecRef: any }) {
  return (
    <div className="bg-black text-white -mt-2" ref={portfolioSecRef}>
      <div className="flex justify-center items-center py-20">
        <h3 className="text-2xl sm:text-4xl font-extralight">
          <span className="mx-2">P</span>
          <span className="mx-2">O</span>
          <span className="mx-2">R</span>
          <span className="mx-2">T</span>
          <span className="mx-2">F</span>
          <span className="mx-2">O</span>
          <span className="mx-2">L</span>
          <span className="mx-2">I</span>
          <span className="mx-2">O</span>
        </h3>
      </div>

      <div className="flex flex-col justify-center items-center gap-10 px-5">
        {/* row 1 */}
        <div className="flex justify-center items-center sm:gap-36 w-full">
          <PortfolioCard
            to={"/underconstruction"}
            className="w-1/2 sm:w-auto"
            imgLoc={"images/portfolio/Architecture-01.png"}
            text={"Architectural Design"}
          />

          <PortfolioCard
            to={"/underconstruction"}
            className="w-1/2 sm:w-auto"
            imgLoc={"images/portfolio/Interior Design-01.png"}
            text={"Interior Design"}
          />

          <PortfolioCard
            to={"/underconstruction"}
            className="hidden sm:block sm:w-auto "
            imgLoc={"images/portfolio/Network Solution-01.png"}
            text={"Network Solution"}
          />
        </div>

        {/* row 2 */}
        <div className="flex justify-center items-center sm:gap-36 w-full">
          <PortfolioCard
            to={"/underconstruction"}
            className="w-1/2 sm:w-auto"
            imgLoc={"images/portfolio/Mella verse-01.png"}
            text={"MellaVerse"}
          />

          <PortfolioCard
            to={"/underconstruction"}
            className="w-1/2 sm:w-auto"
            imgLoc={"images/portfolio/Motion Graphics-01.png"}
            text={"Motion Graphics"}
          />

          <PortfolioCard
            to={"/underconstruction"}
            className="hidden sm:block sm:w-auto "
            imgLoc={"images/portfolio/Data Center-01.png"}
            text={"Data Center"}
          />
        </div>

        {/* row 3 */}
        <div className="flex justify-center items-center sm:gap-36 w-full">
          <PortfolioCard
            to={"/underconstruction"}
            className="w-1/2 sm:w-auto"
            imgLoc={"images/portfolio/Product Visualization-01.png"}
            text={"Product Visualization"}
          />

          <PortfolioCard
            to={"/underconstruction"}
            className="w-1/2 sm:w-auto"
            imgLoc={"images/portfolio/3D Development-01.png"}
            text={"3D Developement"}
          />

          <PortfolioCard
            to={"/underconstruction"}
            className="hidden sm:block sm:w-auto "
            imgLoc={"images/portfolio/IT Solution-01.png"}
            text={"IT Solution"}
          />
        </div>

        {/* remaining rows for mobile view only */}
        <div className="sm:hidden block">
          <div className="flex justify-center items-center w-full">
            <PortfolioCard
              to={"/underconstruction"}
              className=" "
              imgLoc={"images/portfolio/Network Solution-01.png"}
              text={"Network Solution"}
            />

            <PortfolioCard
              to={"/underconstruction"}
              className=""
              imgLoc={"images/portfolio/Data Center-01.png"}
              text={"Data Center"}
            />
          </div>

          <div className="flex justify-center">
            <PortfolioCard
              to={"/underconstruction"}
              imgLoc={"images/portfolio/IT Solution-01.png"}
              text={"IT Solution"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
