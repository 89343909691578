import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";

// Listen-Observe-Read-Think : Create

function CarouselPage({ image, index }: { image: any; index: any }) {
  const [counter, setCounter] = useState(0);
  const [text, setText] = useState("");
  // const texts = ["Listen", "Observe", "Read", "Think", "Create"];
  const texts = ["Listen"];

  let txtToAnimate: any = useRef(texts[0]);

  const animTxtComp = (txt: any) => {
    return (
      <AnimatePresence>
        <motion.div
          key={txt}
          initial={{ y: "30vh", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="absolute bottom-56 right-32 text-6xl font-extrabold text-primary-500"
        >
          {txt}
          {/* {change ? "true" : "false"} */}
        </motion.div>
      </AnimatePresence>
    );
  };

  useEffect(() => {
    const anim = setInterval(() => {
      setText(texts[counter]);
      txtToAnimate.current = animTxtComp(text);
      if (counter !== texts.length - 1) {
        setCounter((c) => c + 1);
      } else {
        setCounter(0);
      }
    }, 2000);

    return () => {
      clearInterval(anim);
    };
  }, [counter]);

  return (
    <div>
      <div className="relative">
        <img src={image} alt="landing-page-1.png" />
        {/* {txtToAnimate.current} */}
      </div>
    </div>
  );
}

export default CarouselPage;
