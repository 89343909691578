import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import SplashScreen from "./SplashScreen";
import UnderConstruction from "./UnderConstruction";

function Router() {
  const [isLoading, setIsLoading] = useState(true);

  const closeLoading = () => setIsLoading(false);

  useEffect(() => {
    const timeout = setTimeout(closeLoading, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Routes>
      {isLoading && <Route index element={<SplashScreen />} />}

      <Route path="/" element={<Home />} />
      <Route path="/underconstruction" element={<UnderConstruction />} />
      <Route path="*" element={<div>Page Not Found</div>} />
    </Routes>
  );
}

export default Router;
