import {
  MailOutlined,
  PhoneOutlined,
  Instagram,
  LinkedIn,
} from "@mui/icons-material";

const SocialLink = ({ to, icon }: { to: string; icon: any }) => {
  return (
    <li className="my-6 flex justify-center items-center">
      <a
        href={to}
        target="_blank"
        className="border border-white hover:border-primary-300 hover:text-primary-300 rounded-full p-2"
      >
        {icon}
      </a>
    </li>
  );
};

const TextField = ({
  type,
  label,
  name,
}: {
  type: string;
  label: string;
  name: string;
}) => {
  return (
    <div className="w-full flex justify-start sm:justify-center">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <label htmlFor="fullname" className="w-24">
          {label}
        </label>
        <input
          name={name}
          type={type}
          className="w-64 rounded-sm py-1 my-4 sm:m-4 outline-primary-700 text-black p-2 font-normal"
        />
      </div>
    </div>
  );
};

function Footer({ contactUsSecRef }: { contactUsSecRef: any }) {
  return (
    <div className="bg-black text-white font-extralight z-40">
      <div className="px-4 sm:px-20 py-32">
        <div className="px-5 sm:px-0 w-full flex flex-col sm:flex-row justify-between gap-0 items-center sm:-ml-32">
          {/* one */}
          <div className="w-full">
            <div className="flex flex-col sm:flex-row justify-end sm:pr-6 items-center gap-8 ">
              <div className="flex flex-col items-center">
                <img
                  src="./images/mella-logo-white.png"
                  alt="mella-logo-on-footer"
                  className="my-2 w-40 h-40"
                />
                <h1 className="my-2">
                  <span>-</span>
                  <span className="font-extralight tracking-widest">
                    VISUALIZING IMAGINATION
                  </span>
                  <span>-</span>
                </h1>
              </div>
              <div>
                <ul className="flex justify-center items-center gap-4 sm:block">
                  {/* gmail */}
                  <SocialLink to={"#"} icon={<MailOutlined />} />
                  {/* Phone */}
                  <SocialLink
                    to={"tel:+251 932 155 566"}
                    icon={<PhoneOutlined />}
                  />
                  {/* instagram */}
                  <SocialLink
                    to={"https://www.instagram.com/mellacreatives/"}
                    icon={<Instagram />}
                  />
                  {/* linkedin */}
                  <SocialLink
                    to={
                      "https://www.linkedin.com/company/mella-creative-studio/"
                    }
                    icon={<LinkedIn />}
                  />
                </ul>
              </div>
            </div>
          </div>

          {/* horizontal divider */}
          <div className="border-b sm:border-b-0 w-48 sm:w-0 my-4 sm:border-r sm:h-52 border-gray-600"></div>

          {/* two */}
          <div className="w-full flex justify-center items-center">
            <p className="text-center sm:text-left text-xs font-extralight text-white sm:px-8">
              Mella Creative Studio is a creative studio located in Addis Ababa,
              Ethiopia. We work in a ranging from Architectural designs to
              interior designs, motions graphics to product visualizations and
              most importantly, we find unique ways to find our clients projects
              to current state of technology. Our work is build on ideas and
              concepts that seek to push boundaries through creative expression
              in all forms of design
            </p>
          </div>

          {/* horizontal divider */}
          <div className="border-b sm:border-b-0 w-48 sm:w-0 my-4 sm:border-r sm:h-52 border-gray-600"></div>

          {/* three */}
          <div
            className="w-full flex justify-start sm:justify-center"
            ref={contactUsSecRef}
          >
            <div className="w-full">
              {/* contact us header */}
              <div className="my-8">
                <h1 className="text-center font-normal">CONTACT US</h1>
              </div>

              {/* Full Name */}
              <TextField type="text" label={"Full Name"} name={"fullname"} />

              {/* Email */}
              <TextField type="email" label={"Email"} name={"email"} />

              {/* comment */}
              <div className="w-full flex justify-start sm:justify-center">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                  <label htmlFor="fullname" className="w-24">
                    Comment
                  </label>
                  <textarea
                    name="fullname"
                    className="w-64 rounded-sm py-2 my-4 sm:m-4  outline-primary-700 text-black p-2 font-normal"
                  />
                </div>
              </div>
              {/* Submit */}
              <div className="w-full flex justify-start">
                <button
                  name="fullname"
                  className="font-bold text-[14px] w-32 rounded-sm py-2 mx-0 my-2 sm:ml-[150px] bg-primary-700 uppercase text-gray-100  hover:bg-primary-600"
                >
                  send email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center text-xs pl-8 pb-4">
        Copyrights&copy; 2022 Mella. All Right Reserved
      </p>
    </div>
  );
}

export default Footer;
