import React from "react";

function UnderConstruction() {
  return (
    <div className="flex justify-center items-center h-screen bg-black overflow-hidden">
      <div className="w-96">
        <img
          src="./images/page-under-construction.png"
          alt="under-construction"
        />
      </div>
    </div>
  );
}

export default UnderConstruction;
