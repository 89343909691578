import { useState } from "react";
import { motion } from "framer-motion";

const scrollToSection = (ref: any) => {
  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: "smooth",
  });
};

function Navbar({
  portfolioSecRef,
  teamSecRef,
  contactUsSecRef,
}: {
  portfolioSecRef: any;
  teamSecRef: any;
  contactUsSecRef: any;
}) {
  // const [activeNav, setActiveNav] = useState("home");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const NavLink = ({
    isActive,
    value,
    link,
    refVal,
  }: {
    isActive: boolean;
    value: string;
    link?: any;
    refVal?: any;
  }) => {
    return (
      <li
        className={`${
          isActive ? "text-primary-500" : "text-inherit"
        } hover:bg-primary-900 sm:hover:bg-none px-4 py-2 text-xs sm:text-base`}
      >
        <button onClick={() => scrollToSection(refVal)}>
          {value.toUpperCase()}
        </button>
      </li>
    );
  };

  return (
    <>
      <div className="bg-black sm:bg-opacity-60 sm:fixed w-screen z-30 relative sm:">
        <div className="flex justify-between items-center text-white mx-4 sm:mx-10">
          {/* left navbar sec */}
          <div>
            <div className="w-20 h-20">
              <img src="images/mella-logo-white.png" alt="Mella-CS Logo" />
            </div>
          </div>
          {/* right navbar sec */}
          <div>
            <button
              className="sm:hidden flex flex-col justify-end items-end w-2 h-5"
              onClick={() => setMobileMenuOpen((open) => !open)}
            >
              <div className="w-10 h-1 mb-1.5 bg-primary-700 rounded-sm "></div>
              <div className="w-6 h-0.5 mb-1.5 bg-primary-700 rounded-sm "></div>
              <div className="w-2 h-1 mb-1.5 bg-primary-700 rounded-sm "></div>
            </button>
            {/* desktop menu */}
            <ul className={`hidden sm:flex justify-center items-center gap-4`}>
              <NavLink isActive={true} value={"home"} link={"#"} />
              <NavLink
                refVal={portfolioSecRef}
                isActive={false}
                value={"portfolio"}
                link={"#"}
              />
              <NavLink
                refVal={teamSecRef}
                isActive={false}
                value={"team"}
                link={"#"}
              />
              <NavLink isActive={false} value={"blog"} link={"#"} />
              <NavLink
                refVal={contactUsSecRef}
                isActive={false}
                value={"contact us"}
                link={"#"}
              />
            </ul>
          </div>
        </div>
      </div>

      {/* mobile menu */}
      <ul
        // initial={{ y: "-100px" }}
        // animate={{ y: 0 }}
        // transition={{ duration: 5 }}
        className={`sm:hidden ${
          mobileMenuOpen ? "block" : "hidden"
        } bg-black text-white`}
      >
        <NavLink isActive={true} value={"home"} link={"#"} />
        <NavLink
          refVal={portfolioSecRef}
          isActive={false}
          value={"portfolio"}
          link={"#"}
        />
        <NavLink
          refVal={teamSecRef}
          isActive={false}
          value={"team"}
          link={"#"}
        />
        <NavLink isActive={false} value={"blog"} link={"#"} />
        <NavLink
          refVal={contactUsSecRef}
          isActive={false}
          value={"contact us"}
          link={"#"}
        />
      </ul>
    </>
  );
}

export default Navbar;
