import { motion } from "framer-motion";
import { useEffect, useState } from "react";

function SplashScreen() {
  return (
    <div className="w-screen h-screen flex justify-center items-center bg-black text-white">
      <motion.div
        initial={{ width: "180px", opacity: 0.5 }}
        animate={{ width: "150px", opacity: 1 }}
        transition={{ duration: 1.5, repeat: 2 }}
        className="w-40"
      >
        <img src="images/mella-logo-white.png" alt="logo" />
      </motion.div>
    </div>
  );
}

export default SplashScreen;
