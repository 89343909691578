import { Component } from "react";
import Slider from "react-slick";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { images } from "./image-data";
import CarouselPage from "./CarouselPage";
import QuoteTxtAnim from "./QuoteTextAnim";

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      // dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide: true,
      speed: 3000,
      autoplaySpeed: 8000,
      pauseOnHover: false,
    };

    return (
      <div className="m-0 overflow-hidden relative">
        <div className="m-0 overflow-clip relative">
          <Slider ref={(slider) => (this.slider = slider)} {...settings}>
            {[
              <CarouselPage key={1} image={images[0]} />,
              <CarouselPage key={2} image={images[1]} />,
              <CarouselPage key={3} image={images[2]} />,
              <CarouselPage key={4} image={images[3]} />,
              <CarouselPage key={5} image={images[4]} />,
            ].map((item, index) => {
              return <div key={index}>{item}</div>;
            })}
          </Slider>

          <QuoteTxtAnim />

          <div className="flex justify-between items-center w-full absolute top-0 left-0 bg-gray-800 bg-opacity-30 h-screen">
            <button
              className="text-xl text-white hover:text-primary-100 hover:bg-black hover:bg-opacity-70 font-bold p-3 border hover:border-primary-500 border-white rounded-full z-10 transition ease-in-out"
              onClick={this.previous}
            >
              <FaAngleDoubleLeft />
            </button>
            <button
              className="text-xl text-white hover:text-primary-100 hover:bg-black hover:bg-opacity-70 font-bold p-3 border hover:border-primary-500 border-white rounded-full z-10 transition ease-in-out"
              onClick={this.next}
            >
              <FaAngleDoubleRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
