export const teamMembers = [
  [
    {
      profileImg: "images/team/Tariku.png",
      name: "Tariku Tesema",
      position: "Network Engineer",
      desc: "Computer and network enthusiast, always have solutions for IT related problems",
    },
    {
      profileImg: "images/team/user.png",
      name: "Workneh Kassahun",
      position: "Principal Architect",
      desc: "CGI nerd, years of visualizations gave him the weirdest imagination.",
    },
    {
      profileImg: "images/team/user.png",
      name: "Rahel Getachew",
      position: "Finance Officer",
      desc: "Everything around her are just number, well known for late payroll",
    },
    {
      profileImg: "images/team/user.png",
      name: "Ermiyas Tesfaye",
      position: "Project Archtect",
      desc: "Revit expert, with high end renders gave him the ultimate skills.",
    },
  ],
  [
    {
      profileImg: "images/team/user.png",
      name: "Mussie G/Slassie",
      position: "Intern Architect",
      desc: "Left hand blender enthusiast, well-known for his 90 days daily render",
    },
    {
      profileImg: "images/team/user.png",
      name: "Amanuel Girma",
      position: "Web & Mobile Developer",
      desc: "Everything around him is 0 & 1. He always try to find a way to code us.",
    },
    {
      profileImg: "images/team/user.png",
      name: "Amanuel Araya",
      position: "Project Archtect",
      desc: "The silent killer, the first one to adopt sketch up-lumion workflow, lumion specialist",
    },
    {
      profileImg: "images/team/user.png",
      name: "MR. X",
      position: "---------",
      desc: "Always looking for talented personnels to join our team",
    },
  ],
];
